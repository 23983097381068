import React, { Component } from 'react'
import ReactTooltip from "react-tooltip";
import SectionTitle from '../Elements/SectionTitle';
import PriceCompareItem from '../SingleItem/PriceCompareItem'

import $ from 'jquery';
import priceData from '../../data/price';


export default class PriceCompare extends Component {
    constructor(props) {
        super(props);
        this.state = priceData;
    }
    componentDidMount() {
        this.pricaDataTable()
    }
    componentWillMount() {
        this.pricaDataTable()
    }

    pricaDataTable() {
        // DIRTY Responsive pricing table JS

        $("ul").on("click", "li", function () {
            var pos = $(this).index() + 2;
            $("tr").find('td:not(:eq(0))').hide();
            $('td:nth-child(' + pos + ')').css('display', 'table-cell');
            $("tr").find('th:not(:eq(0))').hide();
            $('li').removeClass('active');
            $(this).addClass('active');
        });

        // Initialize the media query
        var mediaQuery = window.matchMedia('(min-width: 640px)');

        // Add a listen event
        mediaQuery.addListener(doSomething);

        // Function to do something with the media query
        function doSomething(mediaQuery) {
            if (mediaQuery.matches) {
                $('.sep').attr('colspan', 5);
            } else {
                $('.sep').attr('colspan', 2);
            }
        }

        // On load
        doSomething(mediaQuery);
    }
    render() {
        const featureItems = this.state.priceList.map((item) => {
			return (
					<PriceCompareItem
                    key={item.id}
                    Feature={item.Feature}
                    ToolTipId={item.ToolTipId}
                    ToolTipData={item.ToolTipData}
                    First30={item.First30}
                    Monthly={item.Monthly}
                    MonthlyPro={item.MonthlyPro}
					/>
			);
		});
        return (
            <section className="pricecompare pricing-area" id="pricing">
            <div className="container px-3">
					<div className="row justify-content-center">
						<div className="col-lg-12 text-center">
							<SectionTitle title={this.state.title} text={this.state.text} alignLine="center" />
						</div>
					</div>
                    <div className="row pricing">
            <article>
                <ul>
                    <li className="bg-blue active">
                        <button>First 30 days</button>
                    </li>
                    <li className="bg-blue">
                        <button>Sympl Package</button>
                    </li>
                    <li className="bg-blue ">
                        <button>Pro Package</button>
                    </li>
                   
                </ul>
                <table>
                    <thead>
                        <tr>
                            <th className="hide" />
                            <th className="bg-blue default">First 30 days</th>
                            <th className="bg-blue">Sympl Package</th>
                            <th className="bg-blue ">Pro Package</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>Monthly price</td>
                            <td className="default"><span className="txt-l">Free</span></td>
                            <td><span className="txt-top-l">$</span><span className="txt-l">19</span><span className="txt-top">99</span></td>
                            <td><span className="txt-top-l">$</span><span className="txt-l">29</span><span className="txt-top">99</span></td>
                        </tr>
                        {featureItems}
                    </tbody>
                </table>
            </article>
					</div>
                    </div>
            
            </section>
        )
    }
}
