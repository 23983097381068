import React, { Component } from 'react';
import { BrowserRouter as Router, Route, NavLink as RouterNavLink, Switch } from "react-router-dom";
import { Link } from "react-scroll";
import HomePage from '../MainPages/HomePage';
import Error from '../MainPages/Error';
import {
	Collapse, Navbar, NavbarToggler, NavbarBrand, Nav, NavItem, NavLink, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem
} from 'reactstrap';

class Header extends Component {

	constructor(props) {
		super(props);

		this.toggle = this.toggle.bind(this);
		this.closeNavbar = this.closeNavbar.bind(this);
		this.onLogin = this.onLogin.bind(this);
		// this.handleClickOutside = this.handleClickOutside.bind(this);
		this.state = {
			isOpen: false,
			scrolled: false
		};
	}

	componentDidMount() {
		window.addEventListener('scroll', () => {
			const isTop = window.scrollY < 500;
			if (isTop !== true) {
				this.setState({
					scrolled: true
				});
			} else {
				this.setState({
					scrolled: false
				})
			}
		});
	}

	componentWillMount() {
		document.addEventListener('mousedown', this.handleClickOutside);
	}
	componentWillUnmount() {
		window.addEventListener('scroll');
		document.removeEventListener('mousedown', this.handleClickOutside);
	}
	toggle() {
		this.setState({
			isOpen: !this.state.isOpen
		});
	}
	closeNavbar() {
		this.setState({
			isOpen: false
		});
	}
	// handleClickOutside(event) {
	//     const t = event.target;
	//     if (this.state.isOpen && !t.classList.contains('navbar-toggler')) {
	//         this.closeNavbar();
	//     }
	// }
	onLogin(){
		window.open("https://mystore.csympl.com", "_self");
	}
	render() {
		return (
			<Router >
				<div>
					<header id="header" className={this.state.scrolled ? 'header-scrolled' : ''}>
						<div className="container">
							<Navbar color="" expand="lg">
								<Link
									activeClass="active"
									className="nav-link"
									to="home"
									spy={true}
									smooth={true}
									offset={-70}
									duration={1000} href="home"
								>
									<img className="logo logo1" src={require('../../assets/img/Logo_Dark_v2.png')} alt="" />


									<img className="logo logo2" src={require('../../assets/img/Logo_Dark_v2.png')} alt="" />
								</Link>
								<NavbarToggler onClick={this.toggle} />
								<Collapse isOpen={this.state.isOpen} navbar>
									<Nav className="ml-auto" navbar>
										<NavItem>
											<Link
												activeClass="active"
												className="nav-link"
												to="home"
												spy={true}
												smooth={true}
												offset={-70}
												duration={1000} href="home"
												onClick={this.closeNavbar}
											>
												Home
											</Link>
										</NavItem>
										<NavItem>
											<Link
												activeClass="active"
												className="nav-link"
												to="about"
												spy={true}
												smooth={true}
												offset={-70}
												duration={1000} href="about"
												onClick={this.closeNavbar}
											>
												About
											</Link>
										</NavItem>

										<NavItem>
											<Link
												activeClass="active"
												className="nav-link"
												to="features"
												spy={true}
												smooth={true}
												offset={-70}
												duration={1000}
												href="#features"
												onClick={this.closeNavbar}
											>
												Features
											</Link>
										</NavItem>
										<NavItem>
											<Link
												activeClass="active"
												className="nav-link"
												to="screenshots"
												spy={true}
												smooth={true}
												offset={-70}
												duration={1000}
												href="#screenshots"
												onClick={this.closeNavbar}
											>
												Screenshots
											</Link>
										</NavItem>
										<NavItem>
											<Link
												activeClass="active"
												className="nav-link"
												to="pricing"
												spy={true}
												smooth={true}
												offset={-70}
												duration={1000}
												href="#pricing"
												onClick={this.closeNavbar}
											>
												Pricing
											 </Link>
										</NavItem>
										<NavItem>
											<Link
												activeClass="active"
												className="nav-link"
												to="downloadapp"
												spy={true}
												smooth={true}
												offset={-70}
												duration={1000}
												href="#downloadapp"
												onClick={this.closeNavbar}
											>
												Download
											 </Link>
										</NavItem>
										<NavItem>
											<Link
												activeClass="active"
												className="nav-link"
												to="contact"
												spy={true}
												smooth={true}
												offset={-70}
												duration={1000}
												href="#contact"
												onClick={this.closeNavbar}
											>
												Contact
											 </Link>
										</NavItem>
										<NavItem>
											<Link
												activeClass="active"
												className="nav-link"
												to=""
												spy={true}
												smooth={true}
												offset={-70}
												duration={1000}
												href="#"
												onClick={this.onLogin}
											>
												Login
											 </Link>
										</NavItem>

									</Nav>
								</Collapse>
							</Navbar>
						</div>
					</header>

					<Switch>
						<Route exact strict path={`${process.env.PUBLIC_URL}/`} component={HomePage} />
						<Route component={Error} />
					</Switch>
				</div>
			</Router>
		);
	}
}

export default Header;
