import React, { Component } from 'react';
import VideoBanner from '../Sections/VideoBanner';
import About from '../Sections/About';
import Features from '../Sections/Features';
import Screenshot from '../Sections/Screenshot';
import DashboardScreens from '../Sections/DashboardScreens';
import PriceCompare from '../Sections/PriceCompare';
import Contact from '../Sections/Contact';
import DownloadApp from '../Sections/DownloadApp';
import Footer from '../Sections/Footer';
import ScrollToTopBtn from "../Elements/ScroolToTop";
import 'react-notifications/lib/notifications.css';
import { NotificationContainer } from 'react-notifications';

import Connect from '../Elements/Connect'

class HomePage extends Component {
	constructor(props) {
		super(props);
		this.state = {};
	}

	render() {
		return (
			<main>
				<VideoBanner />
				<About />
				<Features />
				<Screenshot />
				<DashboardScreens />
				<PriceCompare />
				<DownloadApp/>
				<Contact />
				<Footer/>
				<ScrollToTopBtn/>
				<Connect/>
				<NotificationContainer />

			</main>
		);
	}
}

export default HomePage;
