import React, { Component } from "react";

export default class ScrollToTop extends Component {
  constructor(props) {
    super(props);
    this.state = {
      is_visible: false
    };
  }

  componentDidMount() {
    var scrollComponent = this;
    document.addEventListener("scroll", function(e) {
      scrollComponent.toggleVisibility();
    });
  }

  toggleVisibility() {
    if (window.pageYOffset > 300) {
      this.setState({
        is_visible: true
      });
    } else {
      this.setState({
        is_visible: false
      });
    }
  }

  scrollToTop() {
    window.scrollTo({
      top: 0,
      behavior: "smooth"
    });
  }

  render() {
    const { is_visible } = this.state;
    return (
      <div className="scroll-to-top" style={{position: 'fixed', bottom: 60, right: 20, zIndex: 9999999999}}>
        {is_visible && (
          <div onClick={() => this.scrollToTop()}>
            <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" version="1.1" id="Capa_1" x="0px" y="0px" viewBox="0 0 512 512" style={{enableBackground: 'new 0 0 512 512'}} xmlSpace="preserve" height="38.735" width="33.749" className><g><script xmlns className="active-path" style={{}} /><g>
            <g>
              <path d="M256,0C114.833,0,0,114.833,0,256s114.833,256,256,256s256-114.833,256-256S397.167,0,256,0z M383.188,302.75    c-1.646,3.979-5.542,6.583-9.854,6.583h-32c-2.833,0-5.542-1.125-7.542-3.125L256,228.417l-77.792,77.792    c-2,2-4.708,3.125-7.542,3.125h-32c-4.313,0-8.208-2.604-9.854-6.583c-1.604-3.979-0.729-8.583,2.313-11.625l117.333-117.333    c4.167-4.167,10.917-4.167,15.083,0l117.333,117.333c2.042,2.042,3.125,4.771,3.125,7.542    C384,300.042,383.729,301.438,383.188,302.75z" data-original="#000000" className="active-path" fill="#333333" />
            </g>
          </g></g> </svg>
          </div>
        )}
      </div>
    );
  }
}
