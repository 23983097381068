import React from 'react';
import Swiper from 'react-id-swiper';
import 'swiper/css/swiper.css';
import screenshot1 from '../../assets/img/screenshots/WebsiteNetworkCard.png'
import screenshot2 from '../../assets/img/screenshots/WebsiteReceipt.png'
import screenshot3 from '../../assets/img/screenshots/WebsiteTranset.png'
import screenshot4 from '../../assets/img/screenshots/WebsiteUPCReport.png'
import screenshot5 from '../../assets/img/screenshots/SalesDashBoard100.png'
import screenshot6 from '../../assets/img/screenshots/TopProducts100.png'

class DashboardScreens extends React.Component {
	render() {
		const params = {
			slidesPerView: 6,
			spaceBetween: 30,
			loop: true,
			pagination: {},
			autoplay: {
				delay: 5000,
			},
			// navigation: {
			//   nextEl: '.swiper-button-next',
			//   prevEl: '.swiper-button-prev'
			// },
			breakpoints: {
				1024: {
					slidesPerView: 2,
					spaceBetween: 40
				},
				768: {
					slidesPerView: 2,
					spaceBetween: 30
				},
				640: {
					slidesPerView: 1,
					spaceBetween: 40
				},
				320: {
					slidesPerView: 1,
					spaceBetween: 40
				}
			}
		}

		return (
			<section className="theme-bg Screenshots section-blue">
				<div className="container">
					<div className="row slider">
						<div className="col-sm-12 mx-auto">
							<Swiper {...params}>
								<div>
									<div className="title mb-2">Sales Dashboard</div>
									<div>
										<img src={screenshot5} className="img-fluid" alt="" />
									</div>
								</div>
								<div>
									<div className="title mb-2">Top Products and Top 10 Departments</div>
									<div>
										<img src={screenshot6} className="img-fluid" alt="" />
									</div>
								</div>								
								<div>
									<div className="title mb-2">Digital Receipts</div>
									<div>
										<img src={screenshot2} className="img-fluid" alt="" />
									</div>
								</div>
								<div>
									<div className="title mb-2">Transaction Report</div>
									<div>
										<img src={screenshot3} className="img-fluid" alt="" />
									</div>
								</div>
								<div>
									<div className="title mb-2">Category Sales Report</div>
									<div>
										<img src={screenshot4} className="img-fluid" alt="" />
									</div>
								</div>
								<div>
									<div className="title mb-2">Credit/Debit Card Transaction Report</div>
									<div>
										<img src={screenshot1} className="img-fluid" alt="" />
									</div>
								</div>
							</Swiper>
						</div>
					</div>
				</div>
			</section>
		);
	}
}


export default DashboardScreens;
