const featureData = {
	title: 'Features That Matter',
	text: 'Let Us Simplify Your Business',
	featureList: [{
		id: 1,
		// icon: require('../assets/img/features/f1.png'),
		heading: 'Centralized Price Book',
		info: 'Control Price Book of all your locations with a single click',
		PrimaryBgColor: 'app-blue',
		SecondaryBgColor: 'app-blue'
	},
	{
		id: 2,
		// icon: require('../assets/img/features/f2.png'),
		heading: 'Daily Sales Reports',
		info: 'Robust sales reporting providing key insights into your business',
		PrimaryBgColor: 'app-success',
		SecondaryBgColor: 'app-success'
	},
	{
		id: 3,
		// icon: require('../assets/img/features/f3.png'),
		heading: 'Inventory Control',
		info: 'Track inventory by utilizing Inventory Check-in, Inventory Count and Review features',
		PrimaryBgColor: 'app-primary',
		SecondaryBgColor: 'app-primary'
	},
	{
		id: 4,

		heading: 'PLU or ITEM Level Reports',
		info: 'Customized PLU or ITEM Level Reports to track actual sales',
		PrimaryBgColor: 'app-danger',
		SecondaryBgColor: 'app-danger'
	},
	{
		id: 5,

		heading: 'Margin Tracking',
		info: 'Easily recognizable margin feedback optimizes product target margin',
		PrimaryBgColor: 'app-info',
		SecondaryBgColor: 'app-info'
	},
	{
		id: 6,

		heading: 'Effortlessly Execute NAXML Deals',
		info: 'Regain your competitive edge with aggressive and easily executed Multi-Product promotions',
		PrimaryBgColor: 'app-brown',
		SecondaryBgColor: 'app-brown'
	},
	{
		id: 7,

		heading: 'Price Change Scheduling',
		info: 'Never Miss a Vendor Communicated Price change again',
		PrimaryBgColor: 'app-secondary',
		SecondaryBgColor: 'app-secondary'
	},
	{
		id: 8,

		heading: 'Unscanned PLUs/Items List',
		info: 'Effortlessly update new and unscanned items maximizing profitability',
		PrimaryBgColor: 'app-red',
		SecondaryBgColor: 'app-red'
	}
	]
};

export default featureData;