import React, { Component } from 'react'
import SectionTitle from '../Elements/SectionTitle';
import aboutData from '../../data/about';

import MockupPhone from '../../assets/img/mockup/transparent-mobile-app.png'

export default class About extends Component {
    constructor(props) {
        super(props);
        this.state = aboutData;
    }

    render() {

        return (
            <section className="about-area section-blue" id="about">
                <div className="container">
                    <div className="row ">
                        <div className="col-lg-12">
                            <SectionTitle title={this.state.title} text={this.state.text} alignLine="center" />
                        </div>
                    </div>
                    <div className="row mb-5">
                        <div className="col-md-10 mx-auto text-center">
                            <p className="text-justify">
                                Imagine the freedom of remotely managing your convenience store from ANYWHERE, protecting margins and maximizing profits. CSympl back office and mobile App provides an intuitive interface to effortlessly manage and remotely control your C-Store. This product was designed for C-Store owners by a C-Store owner. CSympl implements several key features including centralized price book, inventory management, sales reports, UPC/Item level reports, group pricing, price scheduling, fuel price management, NAXML (BOGO, Special Discounts, etc.,), digital receipts and many more… These features allow for complete customization of your C-Store ownership experience.
                            </p>
                        </div>
                    </div>
                    <div className="text-center">
                        <img src={MockupPhone} className="w-75" alt="" />
                    </div>
                </div>
            </section>
        );
    }
}
