import React from 'react';

function SectionTitle(props) {
	return (
		<div className={`section-title d-flex flex-column align-items-${props.alignLine} mb-${props.mb}`}>
			<h2>{props.title}</h2>
			<div className="line mb-2" />
			<p>{props.text}</p>
		</div>
	);
}

export default SectionTitle;
