import React, { Component } from "react";
import FeatureItem from "../SingleItem/FeatureItem";
import SectionTitle from "../Elements/SectionTitle";
import featureData from "../../data/feature";

import VideoFixed from "../../assets/img/CSympl_With_Music_New.mp4";

export default class Features extends Component {
  constructor(props) {
    super(props);
    this.state = featureData;
  }

  render() {
    const featureItems = this.state.featureList.map((item) => {
      return (
        <div key={item.id} className="col-md-3 col-sm-6 mb-4">
          <FeatureItem
            key={item.id}
            heading={item.heading}
            info={item.info}
            PrimaryBgColor={item.PrimaryBgColor}
            SecondaryBgColor={item.SecondaryBgColor}
          />
        </div>
      );
    });
    return (
      <section className="features-area" id="features">
        <div className="container">
          <div className="row ">
            <div className="col-lg-12">
              <SectionTitle
                title={this.state.title}
                text={this.state.text}
				alignLine="center"
				mb='0'
              />
            </div>
          </div>
        </div>
        <div className="row mx-0">
          <div
            dangerouslySetInnerHTML={{
              __html: `
                    <video
                    loop
                    muted
                    autoplay
                    playsinline
                    src="${VideoFixed}"
                    />,
                `,
            }}
          />
        </div>
        <div className="container">
          <div className="row simple-effet-image clearfix">{featureItems}</div>
        </div>
      </section>
    );
  }
}
