import React, { Component } from 'react'
import SectionTitle from '../Elements/SectionTitle';
import MockupPhone from '../../assets/img/mockup/download-today.png'


export default class DownloadApp extends Component {
    onDownloadApp(){
        window.open("https://apps.apple.com/app/apple-store/id1521144086", "_blank");
    }
    onSignUp() {
        window.open("https://mystore.csympl.com", "_self");
    }

    render() {
        return (
            <section id="downloadapp" className="theme-bg Screenshots section-blue">
				<div className="container">
					<div className="row justify-content-center">
						<div className="col-lg-12 text-center">
							<SectionTitle title="Come Enjoy The Sympl Life, Sign Up Today! " text="Free App Download - Free 30-Day Trial – Hassel Free Sign Up" alignLine="center" />
						</div>
					</div>
					<div className="row">
                    <div className="w-100 d-flex justify-content-center mobileFlow">
                        <button class="primary-btn text-uppercase text-center mr-3"onClick={this.onDownloadApp}>Download App</button>
                        <button class="primary-btn text-uppercase text-center"  onClick={this.onSignUp}>Sign-Up</button>
                    </div>
                    <div className="text-center" style={{paddingTop: '50px'}}>
                        <img src={MockupPhone} className="w-50"  alt=""/>
                    </div>
					</div>
				</div>
			</section>
        )
    }
}
