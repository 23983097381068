import React, { Component } from 'react'
import VideoFixed from '../../assets/img/HeroVideo.mp4'
import MobileVideoFixed from '../../assets/img/iPAD-Animation.mp4'
import ScanDataImg from '../../assets/img/ScanDataLogo.png'

import {
    isIOS
  } from "react-device-detect";

export default class VideoBanner extends Component {
    // constructor(props) {
    //     super(props);
    //     this.onSignUp = this.onSignUp.bind(this);
    // }
    onFreeTrial = () => {
        isIOS ? window.open('https://apps.apple.com/app/apple-store/id1521144086', "_blank") : window.open("https://mystore.csympl.com", "_self");
    }

    render() {
        const mystyle = {
            margin: "-25px 0 0 0",
            fontWeight: "normal"
          };
          const myMobilestyle = {
            margin: "-15px 0 0 0",
            fontWeight: "normal"
          };
        return (
            <section className="video-banner" id="home">
                <div className="vignette" />
                <div className="row gap-y mx-0 desktop-buttons">
                    <div className="container banner-content">
                        <div className="col-lg-6 col-md">
                            <h1 className="text-white">CSympl</h1>
                            <h3 className="text-white" style={mystyle}>C-Store Made Simple </h3>
                            <div className="w-100 d-flex justify-content-start">
                                <button class="secondary-btn text-uppercase text-center mr-3" onClick={this.onFreeTrial}>Free Trial</button>
                                
                            </div>
                            <div className="w-100 d-flex justify-content-start">
                                
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row gap-y mx-0 mobile-buttons">
                    <div className="container banner-content position-relative">
                        <div className="text-left">
                            {/*<img className="logo logo1" src={require('../../assets/img/csLogo.png')} alt="" />*/}
                            <h1 className="text-white">CSympl</h1>
                            <h4 className="text-white" style={myMobilestyle}>C-Store Made Simple  </h4>
                        </div>

                        <div className="w-100 d-flex justify-content-start my-5 ">
                        <button class="secondary-btn text-uppercase text-center mr-3" onClick={this.onFreeTrial}>Free Trial</button>
                        </div>
                        
                    </div>
                </div>
                <div className="position-absolute scan-data">
                            <img src={ScanDataImg}/>
                        </div>
                {/* <div className="mobile-buttons">
                    <button class="secondary-btn text-uppercase text-center mr-3">Free Trial</button>
                    <button class="secondary-btn text-uppercase text-center">Sign Up</button>
                </div> */}
                <div dangerouslySetInnerHTML={{
                    __html: `
                    <video
                    loop
                    muted
                    autoplay
                    playsinline
                    src="${VideoFixed}"
                    class="d-none d-sm-none d-md-block"
                    />,
                ` }} />
                <div dangerouslySetInnerHTML={{
                    __html: `
                    <video
                    loop
                    muted
                    autoplay
                    playsinline
                    src="${MobileVideoFixed}"
                    class="d-block d-sm-block d-md-none"
                    />,
                ` }} />
                {/* <video autoplay loop muted playsinline className="d-none d-sm-none d-md-block">
                    <source src={VideoFixed} type="video/mp4" />
                    <source src={VideoFixed} type="video/ogg" />
                </video>
                <video autoplay loop muted playsinline className="d-block d-sm-block d-md-none">
                    <source src={MobileVideoFixed} type="video/mp4" />
                    <source src={MobileVideoFixed} type="video/ogg" />
                </video> */}
            </section>
        )
    }
}
