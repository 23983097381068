import React from 'react';
import ReactTooltip from "react-tooltip";

function PriceCompareItem(props) {
    return (
        <tr>
            <td>
                <div className="d-flex align-items-center">
                    <span>{props.Feature}</span>
                    <span className="info" data-tip data-for={props.ToolTipId}>?</span>
                    <ReactTooltip id={props.ToolTipId} place="top" effect="solid">
                        {props.ToolTipData}
                                    </ReactTooltip>
                </div>
            </td>
            <td className="default"><span className="">{props.First30}</span></td>
            <td><span className="">{props.Monthly}</span></td>
            <td><span className="">{props.MonthlyPro}</span></td>
        </tr>
    );
}

export default PriceCompareItem;
