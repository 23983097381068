import React, { Component } from 'react'

function FeatureItem(props) {
    return (
        <div className="box-container">
            <div className="box-item">
                <div className="flip-box">
                    <div className={`flip-box-front text-center ${props.PrimaryBgColor}`}>
                        <div className="inner color-white">
                            <h4 className="flip-box-header text-white">
                            {props.heading}
                            </h4>
                            {/* <p>{props.info}</p> */}
                        </div>
                    </div>
                    <div className={`flip-box-back text-center ${props.SecondaryBgColor}`}>
                        <div className="inner color-white">
                            <p>{props.info}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default FeatureItem;