const priceData = {
	title: 'Find The Best Plan For You',
	text:
		'Free 30-Day Trial And No Credit Card Needed To Sign Up',

	priceList: [
		{
			id: 1,
			Feature: 'Users',
			ToolTipId: 'Users',
			ToolTipData: 'Create Cashier, Manager, Asst Manager User Accounts for Approriate Use of APP',
			First30: '1',
			Monthly: '3',
			MonthlyPro: '5'
		},
		{
			id: 21,
			Feature: 'Price Book Manager',
			ToolTipId: 'Price Book Manager',
			ToolTipData: 'Remotely manage entire price book',
			First30: 'Yes',
			Monthly: 'Yes',
			MonthlyPro: 'Yes'
		},
		{
			id: 2,
			Feature: 'Centralized Price Book',
			ToolTipId: 'CentralizedPriceBook',
			ToolTipData: 'Control Price Book of all your locations with a single click, just $10 more a month',
			First30: 'Yes',
			Monthly: 'Optional',
			MonthlyPro: 'Optional'
		},
		{
			id: 3,
			Feature: 'Sales Reports',
			ToolTipId: 'SalesReports',
			ToolTipData: 'Comprehensive Sales Tracking and Progress Monitoring ',
			First30: 'Yes',
			Monthly: 'No',
			MonthlyPro: 'Yes'
		},
		{
			id: 4,
			Feature: 'PLU or ITEM Level Reports',
			ToolTipId: 'PLUorITEMLevelReports',
			ToolTipData: 'Customized PLU or Item Level Reports To Track Actual Sales',
			First30: 'Yes',
			Monthly: 'No',
			MonthlyPro: 'Yes'
		},
		{
			id: 5,
			Feature: 'Schedule Price Changes',
			ToolTipId: 'SchedulePriceChanges',
			ToolTipData: 'Schedule Future Price Changes And Never Miss A Price Change Again',
			First30: 'Yes',
			Monthly: 'Yes',
			MonthlyPro: 'Yes'
		},
		{
			id: 6,
			Feature: 'Inventory Tracking',
			ToolTipId: 'InventoryTracking',
			ToolTipData: 'Track Your Most Important Items With This Customizable Resources',
			First30: 'Yes',
			Monthly: 'No',
			MonthlyPro: 'Yes'
		},
		{
			id: 7,
			Feature: 'Inventory Auditing',
			ToolTipId: 'InventoryAuditing',
			ToolTipData: 'Random Inventory Audit Tool To Point Shrinkage',
			First30: 'Yes',
			Monthly: 'No',
			MonthlyPro: 'Yes'
		},		
		{
			id: 8,
			Feature: 'Group Similar Items',
			ToolTipId: 'GroupSimilarItems',
			ToolTipData: 'Setup Pricing Groups and Update Prices With a Single Click',
			First30: 'Yes',
			Monthly: 'Yes',
			MonthlyPro: 'Yes'
		},	
		{
			id: 9,
			Feature: 'Unscanned Items Report',
			ToolTipId: 'UnscannedItemsReport',
			ToolTipData: 'Shows Unscanned Items at the registers',
			First30: 'Yes',
			Monthly: 'No',
			MonthlyPro: 'Yes'
		},			
		{
			id: 10,
			Feature: 'Multi Product Deals/Discounts',
			ToolTipId: 'MultiProductDeals',
			ToolTipData: 'Simply Create Buy One Get One or Discount When Buy Two or More Deals',
			First30: 'Yes',
			Monthly: 'Yes',
			MonthlyPro: 'Yes'
		},
		{
			id: 11,
			Feature: 'Gas Prices',
			ToolTipId: 'GasPrices',
			ToolTipData: 'Changes Gas Prices On POS and At The Pump',
			First30: 'Yes',
			Monthly: 'Yes',
			MonthlyPro: 'Yes'
		},
		{
			id: 12,
			Feature: 'Digital Receipts',
			ToolTipId: 'DigitalReceipts',
			ToolTipData: 'Never Loose Any Receipts Printed At The Register',
			First30: 'Yes',
			Monthly: 'No',
			MonthlyPro: 'Yes'
		},
		{
			id: 121,
			Feature: 'Requested Items',
			ToolTipId: 'Requested Items',
			ToolTipData: 'Capture Out of Stock Items or Requested Items By Customers for Easy (Re)order',
			First30: 'Yes',
			Monthly: 'Yes',
			MonthlyPro: 'Yes'
		},
		{
			id: 13,
			Feature: 'Self Adhesive Labels',
			ToolTipId: 'SelfAdhesiveLabels',
			ToolTipData: 'Prints Labels with Brothers Label Printer (Printer Not Included)',
			First30: 'Yes',
			Monthly: 'Yes',
			MonthlyPro: 'Yes'
		},
		{
			id: 14,
			Feature: 'Rebate Tracking',
			ToolTipId: 'RebateTracking',
			ToolTipData: 'Input Manufacturer Rebate For Accurate Profit Margin Calculations',
			First30: 'Yes',
			Monthly: 'Yes',
			MonthlyPro: 'Yes'
		},
		{
			id: 15,
			Feature: 'Custom Labels',
			ToolTipId: 'CustomLabels',
			ToolTipData: 'Create Custom Labels For Any Food Service Or Proprietary Needs',
			First30: 'Yes',
			Monthly: 'Yes',
			MonthlyPro: 'Yes'
		},
		{
			id: 16,
			Feature: 'Communication Status',
			ToolTipId: 'CommunicationStatus',
			ToolTipData: 'Rest Assured Your Changes Are Applied',
			First30: 'Yes',
			Monthly: 'Yes',
			MonthlyPro: 'Yes'
		},
		{
			id: 17,
			Feature: 'Free Updates',
			ToolTipId: 'FreeUpdates',
			ToolTipData: 'Keeping You Updated With The Most Recent App Technology',
			First30: 'Yes',
			Monthly: 'Yes',
			MonthlyPro: 'Yes'
		},
		{
			id: 18,
			Feature: 'Support 24/7',
			ToolTipId: 'Support',
			ToolTipData: 'Sales and Service Ready To Serve When You Need It',
			First30: 'No',
			Monthly: 'No',
			MonthlyPro: 'Yes'
		},
	]
};

export default priceData;
