import React, { Component } from 'react';
import { Link } from "react-scroll";
class Footer extends Component {
	constructor(props) {
		super(props);
		this.state = {};
	}

	render() {
		return (
			<footer className="footer-area">
				<div className="container">
					<div className="row copyright-area relative">
						<div className="col-12">
							<div className="copy-right-text text-center text-white d-flex justify-content-between">
							<div>
							©{new Date().getFullYear()} CSympl ALL RIGHTS RESERVED
							</div>
							<div>
							Email: contact@csympl.com
							</div>
							<div className="d-flex align-items-center justify-content-center">
							Powered by <Link className="nav-link p-0 ml-1" to="home"href=""> Object Source Inc</Link>
							</div>

							</div>
						</div>
					</div>
				</div>
			</footer>
		);
	}
}

export default Footer;
