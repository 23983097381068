import React from 'react'
import { Link } from "react-router-dom";

import ConnectImg from '../../assets/img/Connect.png'

import AndroidLogo from '../../assets/img/playStore.png'
import AppStoreLogo from '../../assets/img/appStore.png'
import AndroidLogom from '../../assets/img/playStorem.png'
import AppStoreLogom from '../../assets/img/appStorem.png'

export default function Connect(props) {
    return (
        <div className="d-flex align-items-center" style={{position: 'fixed', bottom: 22, left: 80, zIndex: 9999999999}}>
        <a href="https://connect.csympl.com" target="_blank" className="connect-icon" >
            <img src={ConnectImg} alt="connect to csympl" width="50" />
        </a>
        <span className="lg-display">
            <a href="https://apps.apple.com/kz/app/csympl/id1521144086" className="text-center mx-3" target="_blank"> <img src={AppStoreLogo} width="120"/> </a>
            <a href="https://play.google.com/store/apps/details?id=com.objectsourceinc.csympl&hl=en_US&gl=US" className="text-center" target="_blank"> <img src={AndroidLogo} width="120"/> </a>
         </span>
        <span className="sm-display">
            <a href="https://apps.apple.com/kz/app/csympl/id1521144086" className="text-center mx-3" target="_blank"> <img src={AppStoreLogom} width="45"/> </a>
            <a href="https://play.google.com/store/apps/details?id=com.objectsourceinc.csympl&hl=en_US&gl=US" className="text-center" target="_blank"> <img src={AndroidLogom} width="45"/> </a>
         </span>
        </div> 
    )
}
