import React from 'react';
import Swiper from 'react-id-swiper';
import SectionTitle from '../Elements/SectionTitle';
import 'swiper/css/swiper.css';
import screenshot1 from '../../assets/img/screenshots/1.png'
import screenshot2 from '../../assets/img/screenshots/2.png'
import screenshot3 from '../../assets/img/screenshots/3.png'
import screenshot4 from '../../assets/img/screenshots/4.png'
import screenshot5 from '../../assets/img/screenshots/5.png'


class Screenshot extends React.Component {
	render() {
		const params = {
			slidesPerView: 4,
			spaceBetween: 30,
			loop: true,
			pagination: {},
			autoplay: {
				delay: 5000,
			},
			// navigation: {
			//   nextEl: '.swiper-button-next',
			//   prevEl: '.swiper-button-prev'
			// },
			breakpoints: {
				1024: {
					slidesPerView: 4,
					spaceBetween: 40
				},
				768: {
					slidesPerView: 3,
					spaceBetween: 30
				},
				640: {
					slidesPerView: 2,
					spaceBetween: 20
				},
				320: {
					slidesPerView: 1,
					spaceBetween: 10
				}
			}
		}

		return (
			<section id="screenshots" className="theme-bg Screenshots section-blue">
				<div className="container">
					<div className="row justify-content-center">
						<div className="col-lg-12 text-center">
							<SectionTitle title="Screenshots" text="Intuitive Interface, Easy To Use with Endless Customization" alignLine="center" />
						</div>
					</div>
					<div className="row slider">
						<div className="col-sm-12 mx-auto">
							<Swiper {...params}>
								<div>
									<div className="title mb-2">Customizable Features</div>
									<div>
										<img className="mobileScreen" src={screenshot1} alt="" />
									</div>
								</div>
								<div>
									<div className="title mb-2">Intuitive Search</div>
									<div>
										<img className="mobileScreen" src={screenshot2} alt="" />
									</div>
								</div>
								<div>
									<div className="title mb-2">Profit Management</div>
									<div>
										<img className="mobileScreen" src={screenshot3} alt="" />
									</div>
								</div>
								<div>
									<div className="title mb-2">Confirm Connectivity</div>
									<div>
										<img className="mobileScreen" src={screenshot4} alt="" />
									</div>
								</div>
								<div>
									<div className="title mb-2">Manage Rebates</div>
									<div>
										<img className="mobileScreen" src={screenshot5} alt="" />
									</div>
								</div>
							</Swiper>
						</div>
					</div>
				</div>
			</section>
		);
	}
}


export default Screenshot;
